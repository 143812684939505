:root {
  --header_link_inactive: rgba(0, 0, 0, 0.55);
  --header_link_inactive-hover: rgba(0, 0, 0, 0.8);
  --header_link_active: #fd9468;

  --btn_getStarted-backgroundColor: white;
  --btn_getStarted-color: #fd9468;
  --contactUs_MapsLink: #ff6846;

  --footer_link-color: #18214d;
  --footer_link-color-hover: #fd9468;

  --card-backgroundColor: #f5f6f9;
  --integration-appCard-backgroundColor: white;
  --integration-appCard-type_Color: #fd9468;
}
.mainPaddingContainer{
  padding:0 5%
}
.mainPaddingContainer_sm_7{
  padding: 0 calc(5 * 12% / 7);
}
.link-inactive {
  color: var(--header_link_inactive) !important;
}

.link-inactive:hover {
  color: var(--header_link_inactive-hover) !important;
}

.link-active {
  color: var(--header_link_active) !important;
}

.btn_getStarted {
  background-color: var(--btn_getStarted-backgroundColor);
  color: var(--btn_getStarted-color);
  border: 1px solid var(--btn_getStarted-color) !important;
  padding: 10px 20px;
  border-radius: 10px;
  transition: all ease-in-out 300ms;
}
.btn_getStarted:hover {
  background-color: var(--btn_getStarted-color);
  color: var(--btn_getStarted-backgroundColor);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.01);
}

.btn_getStarted1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 13px 33px;
  border-radius: 3px;
  transition: all ease-in-out 300ms;
  background-color: var(--btn_getStarted-color);
  color: var(--btn_getStarted-backgroundColor);
  border: none;
}
.btn_getStarted1:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.footer_links {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: var(--footer_link-color);
  text-decoration: none;
}
.footer_links:hover {
  color: var(--footer_link-color-hover);
  text-decoration: none;
}
.footer_rights {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.252778px;
  color: #767676 !important;
  text-decoration: none;
  margin: 0 8px;
}

.integration-btn_getStarted {
  color: var(--btn_getStarted-backgroundColor);
  background-color: var(--btn_getStarted-color);
  padding: 10px 30px;
  border-radius: 5px;
  margin: 5px;
  border: none;
  transition: all ease-in-out 300ms;
}

.integration-btn_tryItFree {
  background-color: var(--btn_getStarted-backgroundColor);
  color: var(--btn_getStarted-color);
  padding: 10px 30px;
  border-radius: 5px;
  margin: 5px;
  border: none;
  transition: all ease-in-out 300ms;
}

.integration-getStarted {
  background-color: var(--card-backgroundColor);
  border-radius: 48px 48px 0 0;
}

.integration-btn_getStarted:hover,
.integration-btn_tryItFree:hover {
  transform: scale(1.05);
}
.integration-getStarted h1 {
  font-family: "Aileron";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.8px;
  color: #000000;
  margin-bottom: 24px;
}
.integration-getStarted p {
  font-family: "Aileron";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: #64626a;
}
.contactUs-btn_ContactUs {
  background-color: transparent;
  color: var(--btn_getStarted-color);
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid var(--btn_getStarted-color);
  margin: 5px;
  transition: all ease-in-out 300ms;
}
.contactUs-btn_ContactUs:hover {
  background-color: var(--btn_getStarted-color);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.contactUs-btn_ContactUs,
.integration-btn_getStarted,
.integration-btn_tryItFree {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.2px;
}
.form-switch .form-check-input {
  height: 30px;
  width: 70px;
}

.form-switch .form-check-input:focus {
  border-color: green !important ;
  box-shadow: 0 0 0 0.25rem green;
}

.form-switch .form-check-input:checked {
  background-color: red !important;
}

@media screen and (max-width: 576px) {
  .integration-getStarted {
    border-radius: 26px 26px 0 0;
  }
  .integration-getStarted h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
  }
  .integration-getStarted p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #64626a;
    margin-bottom: 30px;
  }
  .contactUs-btn_ContactUs{
    margin-top: 30px;
  }
  .mainPaddingContainer,.mainPaddingContainer_sm_7{
  padding:0 2%
  }
}
