.container1 {
  margin-bottom: 80px;
  padding-top: 100px;
}
.videoContainer {
  max-width: 800px;
  height: 500px;
  border-radius: 20px;
  margin-bottom: 200px;
}
.C1_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 116%;
  text-align: center;
  color: #18214d;
  margin-bottom: 38px;
}
.C1_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  text-align: center;
  color: #5c6077;
  margin-bottom: 30px;
}
.btn_usingFree {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 30px;
  color: var(--btn_getStarted-color);
  border: 1px solid var(--btn_getStarted-color);
  background-color: white;
  border-radius: 5px;
  transition: all ease-in-out 0.3s;
}
.btn_usingFree:hover {
  transform: scale(1.05);
}

.container2 {
  margin-bottom: 240px;
}

.C2_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--btn_getStarted-color);
  margin-bottom: 16px;
}

.C2_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  color: #000000;
  margin-bottom: 39px;
}

.favAppIcons {
  margin: 10px 2%;
  width: calc(166px);
}

.container3 {
  margin-bottom: 120px;
}

.C3_text1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: -0.938889px;
  color: #1a1a1a;
  max-width: 540px;
  margin-bottom: 30px;
}

.C3_text2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.24px;
  color: #3b3a40;
  max-width: 408px;
  margin-bottom: 51px;
}

.C3_btn_moreFeatures {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  color: var(--btn_getStarted-color);
  border: 1px solid var(--btn_getStarted-color);
  background-color: white;
  transition: all ease-in-out 0.3s;
}
.C3_btn_moreFeatures:hover {
  transform: scale(1.05);
}

.featuresArray_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.45px;
  color: #000000;
}

.featuresArray_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.27px;
  color: #64626a;
}
.C3_right_card {
  margin-bottom: 50px;
}

.container4 {
  margin-bottom: 150px;
}

.container5 {
  margin-bottom: 150px;
}
.C5_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.5px;
  color: #000b33;
}
.btn_allCustomers {
  margin-top: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 5%;
  color: var(--btn_getStarted-color);
  border: 1px solid var(--btn_getStarted-color);
  background-color: white;
  border-radius: 5px;
  transition: all ease-in-out 0.3s;
}
.btn_allCustomers:hover {
  transform: scale(1.05);
}

.pricingPlan_title1 {
  color: var(--btn_getStarted-color);
  margin-bottom: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 108%;
}
.pricingPlan_title2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 80px;
  letter-spacing: -0.5px;
  color: #000b33;
}

.sliderContiner {
  padding: 30px;
}

.btn_slider_left {
  left: 0;
}
.btn_slider_right {
  right: 0;
}

.btn_slider_left svg,
.btn_slider_right svg {
  height: 100%;
  width: 100%;
}
.btn_slider_left,
.btn_slider_right {
  height: 40px;
  width: 40px;
  color: var(--btn_getStarted-color);
  background-color: #F7F7F7;
  border-radius: 5px;
  border: 1px solid var(--btn_getStarted-color);
  transition: all ease-in-out 0.3s;
}
.btn_slider_left:hover,
.btn_slider_right:hover {
  color: white;
  background: var(--btn_getStarted-color);
}

.container6{
  margin-bottom: 100px;
}

@media screen and (max-width: 1200px) {
.integrationsFlexWrap{
  flex-wrap: wrap;
}
}
@media screen and (max-width: 576px) {
  .container1 {
    padding-top: 20px;
    margin-bottom: 40px;
  }
  .videoContainer {
    max-width: 800px;
    height: 410px;
    border-radius: 0px;
    margin-bottom: 70px;
  }
  .container2,
  .container3,
  .container4,
  .container5,
  .container6 {
    margin-bottom: 70px;
  }
  .C1_title {
    font-size: 32px;
    line-height: 139%;
    margin-bottom: 16px;
  }
  .C1_text {
    font-size: 12px;
    line-height: 180%;
  }
  .C2_title {
    font-size: 12px;
    line-height: 34px;
    text-align: start !important;
    margin-bottom: 5px;
  }
  .C2_text {
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    text-align: start;
  }
  .C3_text1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    text-align: start;
    margin-bottom: 5px;
  }
  .C3_text2 {
    font-size: 12px;
    line-height: 23px;
  }
  .favAppIcons {
    margin: 10px 2%;
    width: 72px;
  }
  .featuresArray_title {
    font-size: 22px;
    line-height: 27px;
  }
  .featuresArray_text {
    font-size: 17px;
    line-height: 24px;
  }
  .C5_text {
    font-weight: 600;
    font-size: 21px;
    line-height: 41px;
    letter-spacing: -0.758333px;
    text-align: start !important;
    margin-bottom: 0;
  }
  .testimonialContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .pricingPlan_title1 {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    margin-bottom: 14px;
  }
  .pricingPlan_title2 {
    font-weight: 600;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 31px;
    letter-spacing: 0.5px;
  }
  .btn_allCustomers {
    margin-top: 40px;
    font-size: 12px;
    line-height: 26px;
    padding: 6px 15px;
  }
  .btn_slider_left{
    margin-right: 15px;
  }
  .sliderContiner {
    padding: 0px;
  }
}
