.customerCard {
  background-color: var(--integration-appCard-backgroundColor) !important;
  transition: all ease-in-out 300ms;
  padding: 10px 0px;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  margin:5% ;
}
.customerCard:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #000b33;
  margin-bottom: 0;
}

.jobProfile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000b33;
  opacity: 0.7;
  margin-bottom: 0;
}

.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: #000b33;
}

.feedbackCard_userProfile {
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.profilePic {
  width: 54px;
  height: 54px;
  border-radius: 50vh;
}

@media screen and (max-width: 576px) {
  .name {
    font-size: 18px;
  }
  .profilePic {
    width: 54px;
    height: 54px;
    border-radius: 50vh;
  }
  .jobProfile {
    font-size: 14px;
  }
  .text {
    font-size: 12px;
    line-height: 25px;
    text-align: center;
  }
  .feedbackCard_userProfile{
    justify-content: center;
  }
}
