.container1 {
  padding-top: 100px;
  margin-bottom: 100px;
}

.containerService {
  margin-bottom: 100px;
}
.btn_security {
  border: none;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  display: flex;
  align-items: center;
  padding: 0px 40px 18px 10px;
  transition: all ease-in-out 300ms;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

.container2 {
  background-color: rgba(55, 124, 253, 0.02);
  margin-bottom: 100px;
}

.container3 {
  margin-bottom: 100px;
}
.container2_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #000000;
  margin-bottom: 20px;
}
.container2_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 40px;
}
.btn_security.active {
  color: var(--btn_getStarted-color);
  border-bottom: 2px solid var(--btn_getStarted-color);
  transition: all ease-in-out 300ms;
}

.pricingPlan_title1 {
  color: var(--btn_getStarted-color);
  margin-bottom: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 108%;
}
.pricingPlan_title2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 80px;
  letter-spacing: -0.5px;
  color: #000b33;
}
.C2_content{
  margin-top: 80px;
}
@media screen and (max-width: 576px) {
  .container1 {
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .container2 {
    padding: 10px;
    margin-bottom: 66px;
  }
  .container3{
    margin-bottom: 60px;
  }
  .btn_security {
    font-size: 14px;
    line-height: 26px;
    padding: 0px 12px 8px 4px;
  }
  .container2_title {
    font-size: 34px;
    line-height: 126%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .container2_text {
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 30px;
  }
  .btn_started{
    padding-left: 20%;
    padding-right: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  .C2_content{
    margin-top: 30px;
  }
}
