:root {
  --transition: all ease-in-out 300ms;
}

.durationContainer {
  margin-bottom: 80px;
}
.pricingPlan .durationLabel {
  margin: 0 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.528px;
  color: #182748;
}

.pricingPlan .save {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  background-color: #60fd90;
  padding: 2px 11px;
  border-radius: 50vh;
}

.planCard {
  padding: 10% 2.5%;
  background: #ffffff;
  opacity: 0.8;
  border-radius: 10px;
}
.planCard_Active {
  border: 2px solid var(--btn_getStarted-color);
  transition: var(--transition);
}
.planCard_Inactive {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: var(--transition);
}

.planCard .amount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 110%;
  letter-spacing: -0.5px;
  color: #000b33;
  margin-top: 30px;
  margin-bottom: 10px;
}

.planCard .type {
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  margin-bottom: 20px;
  color: #000b33;
  opacity: 0.8;
}
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eeeeee;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgba(253, 148, 104, 0.4);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--btn_getStarted-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
  background-color: var(--btn_getStarted-color);
  width: 30px;
  height: 30px;
}
.planConditionsMain {
  margin-top: 30px;
  margin-bottom: 40px;
}
.planConditions {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #000b33;
}

.btn_getStarted_inactive {
  padding: 13px 33px;
  border: 2px solid var(--btn_getStarted-color);
  border-radius: 3px;
  background-color: var(--btn_getStarted-backgroundColor);
  color: var(--btn_getStarted-color);
  font-family: "HK Grotesk";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  transition: var(--transition);
}
.btn_getStarted_active {
  padding: 13px 33px;
  border: 2px solid var(--btn_getStarted-color);
  border-radius: 3px;
  color: var(--btn_getStarted-backgroundColor);
  background-color: var(--btn_getStarted-color);
  font-family: "HK Grotesk";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  transition: var(--transition);
}

@media screen and (max-width: 576px) {
  .durationContainer {
    margin-bottom: 20px;
  }
}
