.image {
  height: 64px;
  width: 64px;
}
.C2_text1 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: 1px;
  color: #000000;
}

.C2_text2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin-bottom: 7px;
  color: #64626a;
}

.textBox {
  padding-left: 24px;
}

.experience {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 576px) {
  .image {
    height: 25px;
    width: 25px;
  }
  .C2_text1 {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 0;
  }

  .C2_text2 {
    font-size: 10px;
    line-height: 12px;
  }
  .textBox {
    padding-left: 10px;
  }

  .experience {
    max-width: 100%;
  }
}
