.component1Main {
  margin-bottom: 330px;
}

.textContent {
  padding: 10%;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 136%;
  color: #18214d;
  margin-bottom: 40px;
}
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  color: #5c6077;
}

.btn_usingFree {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid var(--btn_getStarted-color);
  color: var(--btn_getStarted-color);
  background: transparent;
  padding: 10px 20px;
  transition: all ease-in-out 0.3s;
  border-radius: 5px;
}

.btn_usingFree:hover {
  transform: scale(1.05);
}

.imageCard {
  background: rgba(255, 255, 255, 0.57);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  backdrop-filter: blur(67.957px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 12px;
}

@media screen and (max-width: 576px) {
  .component1Main {
    margin-bottom: 70px;
  }
  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 141%;
    margin-bottom: 16px;
  }
  .text {
    font-size: 12px;
    line-height: 180%;
    text-align: justify;
  }
  .imageCard {
    margin-bottom: 40px;
  }
  .btn_usingFree {
    font-size: 12px;
    line-height: 26px;
  }
  .textContent {
    padding: 2%;
  }
}
