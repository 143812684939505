.container1 {
  background: rgba(55, 124, 253, 0.02);
  padding-top: 100px;
  padding-bottom: 50px;
}

.container2 {
  padding-top: 70px;
  padding-bottom: 100px;
}

.C1_btn1 {
  border-radius: 12px;
  text-decoration: none;
  padding: 15px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.175px;
  transition: all ease-in-out 0.3s;
}

.C1_title1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.433333px;
  color: var(--btn_getStarted-color);
  margin-bottom: 16px;
}
.C1_title2 {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 123%;
  color: #060c3c;
  margin-bottom: 32px;
}

.C1_text1 {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
  color: #5d6180;
  margin-bottom: 54px;
}

.C1_callUs {
  background: var(--btn_getStarted-color);
  color: white !important;
  border: 1px solid var(--btn_getStarted-color);
}
.C1_btn1:hover {
  transform: scale(1.05);
}
.C1_emailUs {
  color: var(--btn_getStarted-color) !important;
  background: transparent;
  border: 1px solid var(--btn_getStarted-color);
}
.contactUs {
  position: relative;
  min-height: 850px;
}
.containerForm {
  position: absolute;
  width: 50%;
  right: 0;
  top: 100px;
}
.brandImage {
  max-height: 70px;
}

.C1_right_card {
  background: #ffffff;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 30px;
}
.formTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}
.formDesc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 167%;
  letter-spacing: 0.2px;
  color: #64626a;
}
.formLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
  margin-bottom: 11px;
}
.formInput {
  background: #ffffff;
  border: 1px solid #d1d0d6;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.175px;
  padding: 17px 18px;
  width: 100%;
}

.form_btnSubmit {
  background: var(--btn_getStarted-color);
  border-radius: 10px;
  width: 100%;
  padding: 17px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.container3 {
  margin-bottom: 80px;
}
.C3_row1 {
  margin-bottom: 80px;
}

.C3_title1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 73px;
  color: #000000;
  margin-bottom: 0;
}

.C3_text1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #64626a;
  margin-bottom: 0;
}

.contactCard {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 20px 20px;
}
.cardBody {
  padding: 30px;
}

.contactCard_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #3b3a40;
}

.contactCard_address {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #64626a;
  margin-bottom: 20px;
}

.contactCard_maps {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--contactUs_MapsLink) !important;
  text-decoration: none;
}

@media screen and (max-width: 768px){
  .containerForm {
    position: relative;
    width: 100%;
    right: 0;
    top: 0px;
  }
}

@media screen and (max-width: 576px) {
  .container1 {
    background: rgba(55, 124, 253, 0.02);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .containerForm {
    position: relative;
    width: 100%;
    right: 0;
    top: 0px;
  }
  .brandImageContainer {
    justify-content: space-between;
    display: flex;
  }
  .brandImage {
    max-width: 20%;
  }
  .C1_title1 {
    font-size: 12px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 2.57px;
    margin-bottom: 10px;
  }
  .C1_title2 {
    font-family: 'Inter';
    font-size: 34px;
    line-height: 46px;
    text-align: center;
    margin-bottom: 10px;
  }
  .C1_text1 {
    font-size: 13px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 25px;
  }
  .formTitle {
    font-size: 18px;
    line-height: 22px;
  }
  .formDesc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  .formLabel {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    margin-bottom: 5px;
  }
  .container2 {
    padding-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .C3_row1 {
    margin-bottom: 28px;
  }
  .C3_title1 {
    font-size: 34px;
    line-height: 46px;
    text-align: center;
    margin-bottom: 16px;
  }
  .C3_text1 {
    font-size: 13px;
    line-height: 23px;
    text-align: center;
  }
  .container3{
    margin-bottom: 40px;
  }
}
